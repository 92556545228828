@import '@aurora/shared-client/styles/_variables.pcss';
@import '@aurora/shared-client/styles/mixins/_focus.pcss';
@import '@aurora/shared-client/styles/mixins/_panel.pcss';

.lia-wrap {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  min-width: 0;
}

.lia-article {
  display: flex;
  flex-direction: column;

  @media (--lia-breakpoint-down-sm) {
    padding: 15px !important;
    border-radius: 5px;
  }

  .lia-subject {
    margin-top: 0;

    @media (--lia-breakpoint-down-sm) {
      font-size: var(--lia-font-size-xl);
    }
  }
  .lia-node-title {
    margin-bottom: 15px;
    color: var(--lia-bs-text-muted);
  }
  .lia-cover-image-title {
    margin-bottom: 20px;
    color: var(--lia-bs-body-color);

    @media (--lia-breakpoint-down-md) {
      font-size: var(--lia-font-size-xl);
      line-height: 1.333333333;
    }
  }
  .lia-clamp-title {
    -webkit-line-clamp: 3;

    @media (--lia-breakpoint-down-md) {
      -webkit-line-clamp: 5;
    }
  }
  .lia-section {
    flex: 1 1 auto;
    width: 100%;
  }
}

.lia-article-guide-navigation {
  @include panel-defaults();
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.lia-article:has(+ .lia-article-guide-navigation) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.lia-scroll-to-parent {
  scroll-margin-top: var(--lia-d-navbar-height-sticky);
}

.lia-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  .lia-header-main {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;

    .lia-author-text {
      color: var(--lia-bs-body-color);
    }
    .lia-author-rank {
      vertical-align: middle;
      color: var(--lia-bs-body-color);
    }
    .lia-author-post-time {
      font-size: var(--lia-font-size-xs);
      color: var(--lia-bs-body-color);
    }
  }
  .lia-header-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 4px;
  }
  @media (--lia-breakpoint-down-sm) {
    flex-wrap: wrap;
  }
}

.lia-attachments {
  margin-top: 15px;

  &.lia-with-divider {
    border-top: 1px solid var(--lia-bs-gray-300);
    margin-top: 40px;
    padding-top: 40px;
  }
}

.lia-footer {
  display: flex;
  align-items: flex-start;

  .lia-footer-right {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-top: 15px;
  }
  .lia-footer-left {
    margin-right: 15px;
    margin-top: 15px;

    > div + div {
      margin-top: 20px;
    }
  }
  &.lia-footer-divider {
    padding-top: 25px;
    margin-top: 40px;
    border-top: 1px solid var(--lia-bs-gray-300);
  }
  @media (--lia-breakpoint-down-md) {
    flex-wrap: wrap;

    .lia-has-tags {
      flex-basis: 100%;
    }
  }
}

.lia-title-section {
  padding: 30px 60px 5px;

  @media (--lia-breakpoint-down-md) {
    padding: 30px 20px 5px;
  }
  &.lia-title-section-shift {
    background-color: var(--lia-panel-bg-color);
    transform: translateY(-40px);
    margin-bottom: -40px;
    border-radius: var(--lia-bs-border-radius);
    padding-top: 30px;

    @media (--lia-breakpoint-down-md) {
      transform: translateY(0);
      margin-bottom: 0;
    }
  }
}

.lia-head-wrapper {
  border-radius: var(--lia-bs-border-radius);
  background-color: var(--lia-panel-bg-color);

  @media (--lia-breakpoint-down-xs) {
    border-radius: 0;
  }
  &.lia-head-wrapper-standard,
  &.lia-head-wrapper-wide {
    display: grid;
    grid-template-rows: auto;
    border-radius: 0;
    margin-bottom: 25px;

    .lia-title-section {
      padding-bottom: 15px;
      grid-area: 2/1/3/2;
      z-index: 2;
      .lia-node-title,
      .lia-cover-image-title,
      .lia-author-text,
      .lia-author-rank,
      .lia-author-post-time {
        color: var(--lia-bs-white);
      }
      @media (--lia-breakpoint-down-md) {
        margin-bottom: 0;
        padding-bottom: 10px;
      }
    }
  }
  &.lia-head-wrapper-wide {
    .lia-title-section {
      margin-bottom: 40px;

      @media (--lia-breakpoint-down-md) {
        margin-bottom: 0;
      }
    }
  }
}

.lia-reply-to {
  color: var(--lia-bs-gray-700);
  font-weight: var(--lia-bs-font-weight-bold);
  font-size: var(--lia-bs-font-size-sm);
  margin-left: 5px;

  &:hover {
    color: var(--lia-bs-gray-700);
    text-decoration: underline;
  }
  @include default-focus-outline();

  @media (--lia-breakpoint-down-xs) {
    display: none;
  }
}

.lia-cover-image-view-top {
  margin: 10px 0 25px;
}

.lia-accordion.lia-accordion {
  display: flex;
  position: relative;

  .lia-article {
    @media (--lia-breakpoint-down-xs) {
      padding: 15px;
    }
  }

  .lia-accordion-toggle {
    align-self: flex-start;
    margin: 17px 5px 0 -5px;
    border: none;
    background-color: transparent;
    transition: transform var(--lia-timing-normal), background-color var(--lia-timing-normal);

    @media (--lia-breakpoint-up-sm) {
      transform: rotate(90deg);

      &.lia-accordion-is-closed {
        transform: rotate(0deg);
      }

      &:hover,
      &:focus {
        background-color: hsla(
          var(--lia-bs-black-h),
          var(--lia-bs-black-s),
          var(--lia-bs-black-l),
          0.08
        ) !important;
      }
    }
    @media (--lia-breakpoint-down-xs) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 20px;
      margin: 0;
      box-shadow: none;
      padding: 0 !important;
      background-color: transparent !important;

      .lia-accordion-icon {
        display: none;
      }
    }
  }

  .lia-accordion-header {
    margin-bottom: 0;
  }
}

.lia-accordion-ml {
  @media (--lia-breakpoint-up-sm) {
    &-sm {
      margin-left: 46px;
    }
    &-lg {
      margin-left: 51px;
    }
  }
  @media (--lia-breakpoint-down-xs) {
    &-sm {
      margin-left: 10px;
    }
    &-lg {
      margin-left: 15px;
    }
  }
}

.lia-article.lia-has-comment-banner {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.lia-solved-mini {
  font-size: var(--lia-font-size-xs);
  background-color: var(--lia-forum-solved-color);
  color: var(--lia-bs-white);
  border-radius: var(--lia-bs-border-radius-sm);
  padding: 0 3px;
}

.lia-moved {
  color: var(--lia-bs-gray-700);
  margin-left: 5px;
  padding-left: 2px;
  padding-top: 3px;
  box-shadow: none;

  a:hover {
    text-decoration: none;
  }
}

.lia-message-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0 20px;
  font-size: var(--lia-bs-font-size-sm);
  font-weight: var(--lia-bs-font-weight-normal);
}

.lia-status-change-text {
  display: flex;
  font-size: var(--lia-bs-font-size-sm);
}

.lia-escalate-reply-banner {
  padding-left: 25px;

  @media (--lia-breakpoint-down-sm) {
    padding-left: 0;
  }
}

.lia-solved-topic-background {
  background: hsla(
    var(--lia-forum-solved-color-h),
    var(--lia-forum-solved-color-s),
    var(--lia-forum-solved-color-l),
    8%
  );
}
